<template>
  <router-view class="app"/>
</template>

<script>
export default {
  name: 'App',
};
</script>

<style lang="scss">

body {
  background-color: #000F17;
  padding: 0;
  margin: 0;
}

.app {
  width: 100vw;
  max-width: 100%;
  min-height: 100vh;
}
</style>
