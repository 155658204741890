import { createApp } from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';

axios.defaults.baseURL = 'https://api.rufeis.de';
axios.defaults.withCredentials = true;

createApp(App)
  .use(router)
  .use(VueAxios, axios)
  .mount('#app');
