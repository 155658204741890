<template>
  <div class="home">
    <h3 style="color:white;">wakeup is now natively integrated to
      <a style="color: #1584bf;" href="https://dash.rufeis.de/wakeup">the dashboard</a>.
    </h3>
    <!--Rive v-if="loggedIn" src="power-button.riv" />
    <h3-- v-if="!loggedIn && !loading" style="color: white;">Please Login</h3-->
  </div>
</template>

<script>

// @ is an alias to /src
export default {
  name: 'HomeView',
};
</script>

<style scoped>

.home {
  background-color: #000F17;
  width: 100vw;
  max-width: 100%;
  flex-grow: 2;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

</style>
